import React from 'react';
import {
  BrowserView, MobileView, isBrowser, isMobile,
} from 'react-device-detect';
import { helpers } from '../../constants/commonStyles';
import {
  AnimationWrapper,
  Body,
  Card,
  DojoBanner,
  RecipeScene,
  Gutter,
  HeadlineAndCopy,
  LayoutBlock,
  LoadingSpinner,
  NavigationButtons,
  OptionCards,
  RecipeTitle,
  Header,
  Options,
  InspirationalQuote,
} from '..';
import { HomeContainer, HomeLeftContainer } from './styles';

function HomeScene(props) {
  const {
    recipe,
    isProcessing,
    optionButtonsValue,
    displayRecipes,
    introPhrase,
    currentRecipeIndex,
    recipeStore,
    onRecipeNavigate,
    onOptionValueChange,
    wantsCheese,
    VEGAN,
    enableRecipeScene,
    welcomeMessage,
  } = props;

  const isShortDesktop = isBrowser && window.innerHeight < 809;

  function RecipePreview({ desktop }) {
    if (displayRecipes) {
      return (
        <LayoutBlock marginTop={0} injectStyles={{ height: isShortDesktop ? '160px' : '200px' }}>
          <LayoutBlock marginTop={0}>
            <HeadlineAndCopy marginTop={0} headline="recipe:" />
            <Card
              isShortDesktop={isShortDesktop}
              maintainFullWidth
              onClick={(isProcessing || desktop) ? null : enableRecipeScene}
              injectStyles={isProcessing
                ? {
                  display: 'flex',
                  justifyContent: 'center',
                } : {}}
            >
              {
                isProcessing
                  ? <LoadingSpinner size={95} />
                  : (
                    <RecipeTitle
                      conQueso={wantsCheese}
                      isShortDesktop={isShortDesktop}
                      {...props}
                    />
                  )
              }
            </Card>
          </LayoutBlock>
        </LayoutBlock>
      );
    }
    return null;
  }

  const selectHeadline = optionButtonsValue ? 'your dojo:' : 'select your dojo:';

  return (
    <div className="App">
      <HomeContainer>
        <HomeLeftContainer $isMobile={isMobile} style={{ width: isMobile ? '100%' : '50%' }}>
          <Header isShortDesktop={isShortDesktop} />
          <LayoutBlock row>
            <Gutter />
            <AnimationWrapper cancelWidth fromLeft>
              <Body>
                <LayoutBlock
                  id="containerElement"
                  name="first-scroll"
                  marginTop={0}
                  injectStyles={{ ...helpers.flexVerticalContainer, height: '100%' }}
                >
                  <LayoutBlock marginTop={0}>
                    <LayoutBlock rowIfDesktop marginTop={0} injectStyles={{ justifyContent: 'stretch' }}>
                      <HeadlineAndCopy
                        headline={selectHeadline}
                        injectStyles={{ minWidth: '99px' }}
                        marginTop={1}
                      />
                      <LayoutBlock horizontalSpacer={1} />
                      <DojoBanner {...props} />
                    </LayoutBlock>
                    <LayoutBlock marginTop={3} flexCenter>
                      <OptionCards
                        name="third-scroll"
                        onClick={onOptionValueChange}
                        isShortDesktop={isShortDesktop}
                        value={optionButtonsValue}
                      />
                    </LayoutBlock>
                    {
                      optionButtonsValue ? (
                        <Options {...props} />
                      ) : null
                    }
                    <LayoutBlock marginTop={1}>
                      <AnimationWrapper cancelWidth fromRight>
                        <RecipePreview desktop={isBrowser} />
                      </AnimationWrapper>
                    </LayoutBlock>
                    <NavigationButtons
                      recipeStore={recipeStore}
                      currentRecipeIndex={currentRecipeIndex}
                      onRecipeNavigate={onRecipeNavigate}
                      isProcessing={isProcessing}
                    />
                    <MobileView>
                      <InspirationalQuote welcomeMessage={welcomeMessage} />
                    </MobileView>
                  </LayoutBlock>
                </LayoutBlock>
              </Body>
            </AnimationWrapper>
            <Gutter />
          </LayoutBlock>
        </HomeLeftContainer>
        <BrowserView style={{ width: '50%' }}>
          <RecipeScene
            recipe={displayRecipes ? recipe : null}
            hyperbole={recipe.hyperbole}
            welcomeMessage={welcomeMessage}
            displayRecipes={displayRecipes}
            optionButtonsValue={optionButtonsValue}
            introPhrase={introPhrase}
            VEGAN={VEGAN}
            wantsCheese={wantsCheese}
            isProcessing={isProcessing}
          />
        </BrowserView>
      </HomeContainer>
    </div>
  );
}

export default HomeScene;
