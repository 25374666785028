import { CHIMICHURRI } from '../standard/additionalToppings';
import { } from '../standard/garnish';
import { } from '../standard/mixins';
import { } from '../standard/queso';
import { } from '../standard/toppings';
import { } from '../standard/tortilla';

const SOUTH_AMERICAN = {
  toppings: [],
  additionalToppings: [CHIMICHURRI, CHIMICHURRI],
  garnishes: [],
  mixins: [],
  queso: [],
  tortilla: [],
};

export default SOUTH_AMERICAN;
