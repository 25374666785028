import baseTypes from '../constants/baseTypes';

// eslint-disable-next-line no-undef
const BEEF = require('../assets/categoryImages/BEEF.png');
const VEG = require('../assets/categoryImages/VEG.png');
const CHICKEN = require('../assets/categoryImages/CHICKEN.png');
const SEA = require('../assets/categoryImages/SEA.png');
const BRUNCH = require('../assets/categoryImages/BRUNCH.png');
const PORK = require('../assets/categoryImages/PORK.png');
const INFINITE = require('../assets/categoryImages/INFINITE.png');
const LAMB = require('../assets/categoryImages/LAMB.png');

export const images = {
  [baseTypes.BEEF]: BEEF,
  [baseTypes.CHICKEN]: CHICKEN,
  [baseTypes.SEA]: SEA,
  [baseTypes.PORK]: PORK,
  [baseTypes.VEG]: VEG,
  [baseTypes.LAMB]: LAMB,
  [baseTypes.INFINITE]: INFINITE,
  [baseTypes.BRUNCH]: BRUNCH,
};
