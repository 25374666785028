import randomize from './randomizer';

const introPhrases = [
  'your recipe',
  'a taco to ruin all other tacos',
  "better get your taco-makin' pants out",
  "better get your taco-makin' pants out",
  'a flavor fiesta awaits',
  'the taco your taste buds deserve',
  "the taco your ex can't compete with",
  "brace yourself – this one's a banger",
  'hold onto your sombrero',
];

export function getIntroPhrase() {
  return randomize(introPhrases);
}
