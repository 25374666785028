export const pairingHandler = (recipes, baseType) => {
  try {
    return recipes.reduce((acc, recipe) => {
      recipe.basePairings.forEach((pairing) => {
        if (baseType === pairing) {
          acc.push(recipe);
        }
      });
      return acc;
    }, []);
  } catch (e) {
    return recipes;
  }
};

export default pairingHandler;
