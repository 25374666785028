import { styled } from 'styletron-react';
import { helpers, colors } from '../../constants/commonStyles.js';

const getBorderString = (color) => `0.125rem solid ${color}`;

const getColorScheme = (isSelected) => {
  if (isSelected) {
    return {
      backgroundColor: colors().tertiary,
      ':disabled': { backgroundColor: 'silver' },
    };
  }
  return {
    border: getBorderString(colors().tertiary),
    ':hover': { backgroundColor: colors().tertiaryLight },
  };
};

export const Container = styled('div', ({ $isSelected }) => ({
  ...helpers.flexVerticalCenter,
  borderRadius: '0.25rem',
  height: '26px',
  width: '27px',
  cursor: 'pointer',
  ...getColorScheme($isSelected),
}));

export const OuterContainer = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
}));
