import { styled } from 'styletron-react';
import { isMobile } from 'react-device-detect';
import { colors, borderSmoothing, shadow } from '../../constants/commonStyles.js';

export const Container = styled('div', () => ({
  backgroundColor: 'white',
  minWidth: isMobile ? '97%' : '73%',
  marginTop: isMobile ? '-1' : '-3px',
  padding: '2px',
  borderTopWidth: '2px',
  borderBottomWidth: '2px',
  borderTopStyle: 'solid',
  borderBottomStyle: 'solid',
  marginRight: '5px',
  marginLeft: '5px',
  borderColor: colors().secondary,
  ...borderSmoothing,
  ...shadow,
}));

export default { Container };
