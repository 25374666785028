import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Quicksand',
  src: 'https://fonts.gstatic.com/s/quicksand/v13/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv58a-xDwxUD2GFw.woff',
});

export const styles = StyleSheet.create({
  page: {
    padding: 14,
  },
  section: {
    margin: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '20px',
    borderBottom: '1px solid silver',
  },
  normalText: {
    fontSize: 12,
    fontFamily: 'Quicksand',
  },
  smallText: {
    fontSize: 9,
    fontFamily: 'Quicksand',
  },
  smallImage: {
    height: '37px',
    width: 'auto',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginTop: {
    marginTop: '8px',
  },
  divider: {
  },
  baseSection: {
    border: '1px solid silver',
    borderRadius: '3',
    width: '48.4%',
    height: 'auto',
    padding: 5,
  },
});
