// import {  } from '../standard/additionalToppings';
import { ARUGULA } from '../standard/garnish';
import { CARAMELIZED_SHALLOTS } from '../standard/mixins';
import { CHEDDAR, WHITE_CHEDDAR, SMOKED_GOUDA } from '../standard/queso';
import {
  CORN_RELISH,
  SMOKED_SALSA,
  CHIPOTLE_PINEAPPLE_SAUCE,
  CHARRED_TOMATO_RELISH,
} from '../standard/toppings';
// import {  } from '../standard/tortilla';

const BBQ = {
  toppings: [
    CORN_RELISH,
    SMOKED_SALSA,
    CHIPOTLE_PINEAPPLE_SAUCE,
    CHARRED_TOMATO_RELISH,
  ],
  additionalToppings: [],
  garnishes: [ARUGULA],
  mixins: [CARAMELIZED_SHALLOTS],
  queso: [CHEDDAR, WHITE_CHEDDAR, SMOKED_GOUDA],
  tortilla: [],
};

export default BBQ;
