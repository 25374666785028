import { styled } from 'styletron-react';
import { StyleSheet, Font } from '@react-pdf/renderer';
import { media } from '../../constants/commonStyles';

const shortDesktopStyles = {
  fontSize: '14px',
  lineHeight: '9px',
};

export const RecipeText = styled('span', ({ $isShortDesktop }) => ({
  fontSize: '15px',
  [`@media (min-width: ${media.midWidth})`]: {
    fontSize: '17px',
    ...$isShortDesktop ? shortDesktopStyles : {},
  },
  [`@media (min-width: ${media.wideWidth})`]: {
    fontSize: '18px',
    ...$isShortDesktop ? shortDesktopStyles : {},
  },
  ...$isShortDesktop ? shortDesktopStyles : {},
}));

// PFD Styles

Font.register({
  family: 'Quicksand',
  src: 'https://fonts.gstatic.com/s/quicksand/v13/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv58a-xDwxUD2GFw.woff',
});

export const styles = StyleSheet.create({
  normalText: {
    fontSize: 12,
    fontFamily: 'Quicksand',
  },
  marginTop: {
    marginTop: '8px',
  },
});

export default {
  RecipeText,
};
