import React from 'react';
import { HeadlineAndCopy, LayoutBlock, AnimationWrapper } from '..';
import { baseTypes } from '../../constants';
import { Container } from './styles';

const dojoPhrases = {
  [baseTypes.BEEF]: 'House of Beef',
  [baseTypes.BRUNCH]: 'The Brunch Temple',
  [baseTypes.CHICKEN]: 'The Path of Poultry',
  [baseTypes.LAMB]: 'House of Lamb',
  [baseTypes.PORK]: 'House of Pork',
  [baseTypes.SEA]: 'The Way of the Sea',
  [baseTypes.VEG]: 'The Veggie Temple',
  [baseTypes.INFINITE]: 'House of Infinite Possibilities',
};

const DojoBanner = ({ optionButtonsValue }) => {
  return optionButtonsValue ? (
    <AnimationWrapper fromRight>
      <Container>
        <LayoutBlock marginTop={0} flexCenter>
          <HeadlineAndCopy headline={dojoPhrases[optionButtonsValue]} injectStyles={{ marginBottom: '-9px' }}/>
        </LayoutBlock>
      </Container>
    </AnimationWrapper>
  ) : null;
};

export default DojoBanner;
