import { SPICED_YOGURT_SAUCE, MINT_CHUTNEY } from '../standard/additionalToppings';
import { } from '../standard/garnish';
import { POMEGRANITE_SEEDS } from '../standard/mixins';
import { } from '../standard/queso';
import { } from '../standard/toppings';
import { } from '../standard/tortilla';

const MIDDLE_EASTERN = {
  toppings: [],
  additionalToppings: [SPICED_YOGURT_SAUCE, SPICED_YOGURT_SAUCE, MINT_CHUTNEY],
  garnishes: [],
  mixins: [POMEGRANITE_SEEDS],
  queso: [],
  tortilla: [],
};

export default MIDDLE_EASTERN;
