import { } from '../standard/additionalToppings';
import { } from '../standard/garnish';
import { } from '../standard/mixins';
import { } from '../standard/queso';
import { CHARRED_TOMATO_RELISH, CHIPOTLE_ROMESCO } from '../standard/toppings';
import { } from '../standard/tortilla';

const SPANISH = {
  toppings: [CHARRED_TOMATO_RELISH, CHIPOTLE_ROMESCO],
  additionalToppings: [],
  garnishes: [],
  mixins: [],
  queso: [],
  tortilla: [],
};

export default SPANISH;
