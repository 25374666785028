import { styled } from 'styletron-react';

export const Container = styled('div', () => ({
  display: 'flex',
  width: 'auto',
  justifyContent: 'flex-end',
  alignItems: 'middle',
}));

export default {
  Container,
};
