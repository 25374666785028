import { dietaryCodes, baseTypes } from '../../constants';

export const ASIAN_SLAW = {
  name: 'asian slaw',
  code: 'as',
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  basePairings: [
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  ingredients: [
    {
      name: 'shredded red cabbage',
      measurement: '4 oz',
    },
    {
      name: 'shredded carrots',
      measurement: '4 oz',
    },
    {
      name: 'shredded lettuce',
      measurement: '4 oz',
    },
    {
      name: 'green onions',
      measurement: '3 diced',
    },
    {
      name: 'cilantro',
      measurement: '1/2 cup firmly packed fresh flat-leaf, chopped',
    },
    {
      name: 'light olive oil',
      measurement: '2 tbsp',
    },
    {
      name: 'rice wine vinegar',
      measurement: '2 tbsp',
    },
    {
      name: 'sesame oil',
      measurement: '1 tbsp',
    },
    {
      name: 'honey (or maple syrup)',
      measurement: '2 tbsp',
    },
    {
      name: 'soy sauce',
      measurement: '1 tbsp',
    },
    {
      name: 'garlic',
      measurement: '1 minced clove',
    },
    {
      name: 'ginger',
      measurement: '1 big tsp fresh grated',
    },
    {
      name: 'sesame seeds',
      measurement: '1 tbsp',
    },
    {
      name: 'chili garlic sauce',
      measurement: '1/2 tsp',
    },
  ],
  difficulty: 4,
  description: 'Give your taco the zesty, bold crunch it needs.',
  preparation: [
    'Toss all shredded ingredients, cilantro, and green onions together in a large mixing bowl.',
    'Whisk all other ingredients, except for the sesame seeds, together in a small mixing bowl.',
    'Pour the dressing into the large bowl and toss thoroughly.',
    'Finish with sesame seeds and toss a bit more.',
  ],
};

export const CHARRED_TOMATO_RELISH = {
  name: 'charred tomato relish',
  code: 'ctr',
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  basePairings: [
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.BRUNCH,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  ingredients: [
    {
      name: 'large vine-ripe tomatoes',
      measurement: '5 halved',
      imageCode: 'tomatoes',
    },
    {
      name: 'olive oil',
      measurement: '2 tbsp',
      imageCode: 'olive-oil',
    },
    {
      name: 'kosher salt',
      measurement: '1/2 tsp',
      imageCode: 'salt',
    },
    {
      name: 'black pepper',
      measurement: '1/4 tsp',
      imageCode: 'black-pepper',
    },
    {
      name: 'cilantro',
      measurement: '1/4 cup firmly packed fresh flat-leaf, chopped',
      imageCode: 'parsley',
    },
    {
      name: 'lime',
      measurement: '1/2 juiced',
      imageCode: 'lime',
    },
    {
      name: 'red wine vinegar',
      measurement: '1 tbsp',
      imageCode: 'red-wine-vinegar',
    },
    {
      name: 'garlic',
      measurement: '1 clove chopped',
      imageCode: 'garlic',
    },
    {
      name: 'honey (or brown sugar)',
      measurement: '1 tsp',
      imageCode: 'honey',
    },
    {
      name: 'chili flake',
      measurement: '1/4 tsp',
      imageCode: 'chili-flake',
    },
  ],
  difficulty: 3,
  description: '',
  preparation: [
    'Toss tomatoes with 1 tbsp olive-oil, salt, and pepper, and set aside.',
    'Grill tomatoes on a grill pan or outdoors, turning occasionally, until charred and softened, about 5 minutes.',
    'Remove from grill and allow to cool.',
    'Coarsely chop tomatoes, then transfer to a large bowl. Mix with all remaining ingredients and toss/stir to combine.',
  ],
};

export const CHIPOTLE_PINEAPPLE_SAUCE = {
  name: 'chipotle pineapple sauce',
  code: 'cps',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.PORK,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'pineapple',
      measurement: '1/2 fresh finely chopped',
      imageCode: 'pineapple',
    },
    {
      name: 'red onion',
      measurement: '1 minced',
      imageCode: 'red-onion',
    },
    {
      name: 'olive oil',
      measurement: '1 1/2 tbsp',
      imageCode: 'olive-oil',
    },
    {
      name: 'chipotle peppers in abodo',
      measurement: '2-3 chopped',
      imageCode: 'chipotle',
    },
    {
      name: 'salt',
      measurement: '1/4 tsp',
      imageCode: 'salt',
    },
  ],
  difficulty: 3,
  description: 'Sweet, spicy, and oh so bold.',
  preparation: [
    'Sautee the onion in the oil until translucent.',
    'Add pineapple and let cook for just a couple minutes.',
    'Add 2 tbsp water, chipotle peppers, and 1 tbsp sauce abodo sauce from can and simmer until reduced to just thinner than a paste.',
    'This will produce more than you will likely need for your tacos. Use the remainder as a dip within a week, or freeze indefinitely.',
  ],
};

export const CHIPOTLE_ROMESCO = {
  name: 'chipotle romesco',
  code: 'chrm',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'garlic',
      measurement: '3 cloves',
      imageCode: 'garlic',
    },
    {
      name: 'almonds',
      measurement: '1/4 cup raw',
      imageCode: 'almonds',
    },
    {
      name: 'fire-roasted tomatoes',
      measurement: '1 12-15 oz can',
      imageCode: 'tomatoes',
    },
    {
      name: 'olive oil',
      measurement: '1 1/2 tbsp',
      imageCode: 'olive-oil',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
      imageCode: 'lime',
    },
    {
      name: 'paprika',
      measurement: '1/2 tsp',
      imageCode: 'paprika',
    },
    {
      name: 'cumin',
      measurement: '1/2 tsp',
      imageCode: 'cumin',
    },
    {
      name: 'chipotle peppers in abodo',
      measurement: '1-2',
      imageCode: 'chipotle',
    },
    {
      name: 'maple syrup',
      measurement: '1 tbsp',
      imageCode: 'maple-syrup',
    },
    {
      name: 'salt',
      measurement: '1/4 tsp',
      imageCode: 'salt',
    },
  ],
  difficulty: 3,
  description: 'If the city of Barcelona was a taco smear, this would be it.',
  preparation: [
    'Add tomatoes, garlic, almonds, lime juice, paprika, cumin, maple syrup, olive oil, salt, and chipotle peppers to a high speed blender or food processor.',
    'Blend on high until mixture is smooth. Add olive oil, salt, and/or lime juice to balance and tune the romesco to your taste.',
    'This will produce more than you will likely need for your tacos. Use the remainder as a dip within a week, or freeze indefinitely.',
  ],
};

export const KIMCHI = {
  name: 'kimchi',
  code: 'kmch',
  difficulty: 2,
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
  ],
  basePairings: [],
  ingredients: [
    {
      name: 'kimchi',
      measurement: '1 tbsp per taco',
      imageCode: 'kimchi',
    },
  ],
  description: '*****',
  preparation: [
    'The ninja is a master of many crafts, but the fermentation of cabbage is sadly not among them. He tends to eat it too quickly.',
    "If you'd like to make your own kimchi, google is your friend. Otherwise, the store-bought varieties are a safe bet.",
  ],
};

export const LIME_SLAW = {
  name: 'lime slaw',
  code: 'ls',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'bag cole slaw mix',
      measurement: '1 14oz bag',
      imageCode: 'cole-slaw',
    },
    {
      name: 'mayonnaise',
      measurement: '1/2 cup',
      imageCode: 'mayonnaise',
    },
    {
      name: 'dijon mustard',
      measurement: '1/2 tsp',
      imageCode: 'dijon-mustard',
    },
    {
      name: 'honey',
      measurement: '1/2 tsp',
      imageCode: 'honey',
    },
    {
      name: 'apple cider vinegar',
      measurement: '1 1/2 tsp',
      imageCode: 'apple-cider-vinegar',
    },
    {
      name: 'salt',
      measurement: '1/4 tsp',
      imageCode: 'salt',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
      imageCode: 'lime',
    },
    {
      name: 'black pepper',
      measurement: '1 pinch',
      imageCode: 'black-pepper',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'In a large mixing bowl, add all of the ingredient except the cole slaw mix and stir until the mixture is smooth and creamy.',
    'Add cole-slaw mix and continue stirring until everything is evenly coated. Refrigerate until ready for use.',
  ],
};

export const SMOKED_SALSA = {
  name: 'smoked salsa',
  code: 'ss',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'corn',
      measurement: '2 ears shucked',
      imageCode: 'corn',
    },
    {
      name: 'tomatoes',
      measurement: '4 ripe, halved',
      imageCode: 'vine-ripe-tomato',
    },
    {
      name: 'jalepeños',
      measurement: '2 halved',
      imageCode: 'jalepeno',
    },
    {
      name: 'cilantro',
      measurement: '1/2 cup chopped fresh',
      imageCode: 'cilantro',
    },
    {
      name: 'salt',
      measurement: '1/2 tsp',
      imageCode: 'salt',
    },
  ],
  difficulty: 5,
  description: "Almost by definition, any use of a smoker for tacos can be considered an effort above and beyond. If you prefer the 'easy' route, put some wood chips in your grill on one side, and put your favorite store-bought salsa on the opposite side in a shallow pan and smoke at a low temperature for 30-40 minutes, stirring occasionally. Cool before serving.",
  preparation: [
    'Preheat smoker (preferably) or grill to 225 degrees.',
    'Add your preferred wood type.',
    'Place all vegetables on the cooler side of the smoker. Smoke for 20-30 minutes. Remove vegetables and allow to cool.',
    "Using a chef's knife, carefully slice the corn kernals off the cob and into a mixing bowl.",
    'Place tomatoes, jalapeños, onion, and a small splash of lime juice in a food processor, and chop coarsely. Add to the mixing bowl with the corn and stir in the cilantro, lime juice, and salt to taste.',
  ],
};

export const TRADITIONAL_TACO_RELISH = {
  name: 'traditional taco relish',
  code: 'ttr',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'red onion',
      measurement: '1 diced',
      imageCode: 'red onion',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
      imageCode: 'lime',
    },
    {
      name: 'cilantro',
      measurement: '1 handful',
      imageCode: 'chopped',
    },
    {
      name: 'salt',
      measurement: '1 pinch',
      imageCode: 'salt',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'combine all ingredients and stir.',
  ],
};

export const MANGO_GUAC = {
  name: 'mango guacamole',
  code: 'mg',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'avocados',
      measurement: '3 ripe',
      imageCode: 'avocado',
    },
    {
      name: 'mango',
      measurement: '1 ripe',
      imageCode: 'mango',
    },
    {
      name: 'red onion',
      measurement: '1/2 finely chopped',
      imageCode: 'red-onion',
    },
    {
      name: 'roma tomatoes',
      measurement: '2 diced',
      imageCode: 'roma-tomato',
    },
    {
      name: 'cilantro',
      measurement: '1/2 cup chopped fresh',
      imageCode: 'cilantro',
    },
    {
      name: 'garlic',
      measurement: '(optional) 2 cloves minced',
      imageCode: 'garlic',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
      imageCode: 'lime',
    },
    {
      name: 'sea salt',
      measurement: '1/2 tsp',
      imageCode: 'salt',
    },
  ],
  difficulty: 3,
  description: 'Mango makes the perfect sweet compliment to guacamole',
  preparation: [
    'Slice the avocados in half, remove the pit and skin and place in a mixing bowl.',
    'Slice the mango into cubes.',
    "Mash the avocado with a fork and make it as chunky or smooth as you'd like.",
    'Add the remaining ingredients and stir together.',
  ],
};

export const GUAC = {
  name: 'guacamole',
  code: 'g',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'avocados',
      measurement: '3 ripe',
      imageCode: 'avocado',
    },
    {
      name: 'red onion',
      measurement: '1/2 finely chopped',
      imageCode: 'red-onion',
    },
    {
      name: 'roma tomatoes',
      measurement: '2 diced',
      imageCode: 'roma-tomato',
    },
    {
      name: 'cilantro',
      measurement: '1/2 cup chopped fresh',
      imageCode: 'cilantro',
    },
    {
      name: 'garlic',
      measurement: '(optional) 2 cloves minced',
      imageCode: 'garlic',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
      imageCode: 'lime',
    },
    {
      name: 'sea salt',
      measurement: '1/2 tsp',
      imageCode: 'salt',
    },
  ],
  difficulty: 2,
  description: "If you've never made your own guac, today is the day. This might be the most essential paste you'll learn to concoct in all of your culinary days.",
  preparation: [
    'Slice the avocados in half, remove the pit and skin and place in a mixing bowl.',
    "Mash the avocado with a fork and make it as chunky or smooth as you'd like.",
    'Add the remaining ingredients and stir together.',
  ],
};

export const CORN_RELISH = {
  name: 'corn relish',
  code: 'cr',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGAN,
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'corn',
      measurement: '2 ears husked',
      imageCode: 'corn',
    },
    {
      name: 'green onions',
      measurement: '6',
      imageCode: 'green-onion',
    },
    {
      name: 'red onion',
      measurement: '1/2 finely chopped',
      imageCode: 'red-onion',
    },
    {
      name: 'olive oil',
      measurement: '1 tbsp',
      imageCode: 'olive-oil',
    },
    {
      name: 'chipotle powder',
      measurement: '1/4 tsp',
      imageCode: 'chipotle',
    },
    {
      name: 'cilantro',
      measurement: '1/4 cup fresh chopped',
      imageCode: 'cilantro',
    },
    {
      name: 'chives',
      measurement: '2 tbsp fresh chopped',
      imageCode: 'chives',
    },
    {
      name: 'lime',
      measurement: '1 zested and juiced',
      imageCode: 'lime',
    },
  ],
  difficulty: 3,
  description: '',
  preparation: [
    'Coat green onions and corn with olive oil and season with salt and pepper. Grill until slightly charred, turning occasionally, about 2 minutes for green onions and 8 minutes for corn.',
    'Allow to cool for 5 minutes, then cut the corn from cob and transfer the kernels to a bowl. Chop green onions and add to corn.',
    'Stir in cilantro, chives, lime zest, chipotle powder, the lime juice and a small splash of olive oil.',
    'Add salt and pepper to taste.',
  ],
};

export const TOPPINGS = [
  ASIAN_SLAW,
  CHARRED_TOMATO_RELISH,
  CHIPOTLE_PINEAPPLE_SAUCE,
  CHIPOTLE_ROMESCO,
  CORN_RELISH,
  GUAC,
  KIMCHI,
  LIME_SLAW,
  MANGO_GUAC,
  SMOKED_SALSA,
  TRADITIONAL_TACO_RELISH,
];

export const toppingsToAvoidGarnish = [
  'asian slaw',
  'kimchi',
  'lime slaw',
  'traditional taco relish',
];

export default TOPPINGS;
