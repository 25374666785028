import { styled } from 'styletron-react';
// import { animation } from '../../constants/commonStyles';
import {
  slideInLeft, slideOutLeft, slideInRight, slideOutRight, slideInDown,
} from 'react-animations';

const animationDuration = '0.75s';

const fromLeft = {
  animationName: slideInLeft,
  animationDuration,
};

const toLeft = {
  animationName: slideOutLeft,
  animationDuration,
};

const fromRight = {
  animationName: slideInRight,
  animationDuration,
};

const toRight = {
  animationName: slideOutRight,
  animationDuration,
};

const fromTop = {
  animationName: slideInDown,
  animationDuration,
};

export const Container = styled('div', ({
  $fromTop, $cancelWidth, $fromLeft, $fromRight, $toLeft, $toRight,
}) => ({
  width: $cancelWidth ? '' : '100%',
  ...$fromLeft ? fromLeft : {},
  ...$fromRight ? fromRight : {},
  ...$toLeft ? toLeft : {},
  ...$toRight ? toRight : {},
  ...$toRight ? toRight : {},
  ...$fromTop ? fromTop : {},
}));

export default { Container };
