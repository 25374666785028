import {
  YUMYUM_SAUCE,
  SRIRACHA_MAYO,
  WHIPPED_COCONUT_CREAM,
  SOY_LIME_DRESSING, THAI_PEANUT_SAUCE,
} from '../standard/additionalToppings';
import { CILANTRO } from '../standard/garnish';
import { CHOPPED_MANGO, PICKLED_RED_ONION } from '../standard/mixins';
import { } from '../standard/queso';
import { LIME_SLAW, MANGO_GUAC, ASIAN_SLAW } from '../standard/toppings';
import { } from '../standard/tortilla';

const SOUTHEAST_ASIAN = {
  toppings: [LIME_SLAW, MANGO_GUAC, ASIAN_SLAW],
  additionalToppings: [
    YUMYUM_SAUCE,
    SRIRACHA_MAYO,
    WHIPPED_COCONUT_CREAM,
    WHIPPED_COCONUT_CREAM,
    SOY_LIME_DRESSING,
    THAI_PEANUT_SAUCE,
    THAI_PEANUT_SAUCE,
  ],
  garnishes: [CILANTRO, CILANTRO],
  mixins: [CHOPPED_MANGO, PICKLED_RED_ONION],
  queso: [],
  tortilla: [],
};

export default SOUTHEAST_ASIAN;
