import { YUMYUM_SAUCE, SOY_LIME_DRESSING } from '../standard/additionalToppings';
import { } from '../standard/garnish';
import { } from '../standard/mixins';
import { } from '../standard/queso';
import { } from '../standard/toppings';
import { } from '../standard/tortilla';

const JAPANESE = {
  toppings: [],
  additionalToppings: [YUMYUM_SAUCE, SOY_LIME_DRESSING],
  garnishes: [],
  mixins: [],
  queso: [],
  tortilla: [],
};

export default JAPANESE;
