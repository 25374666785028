import { SOY_LIME_DRESSING, THAI_PEANUT_SAUCE, WHIPPED_COCONUT_CREAM } from '../standard/additionalToppings';
import { } from '../standard/garnish';
import { PICKLED_RED_ONION } from '../standard/mixins';
import { } from '../standard/queso';
import { KIMCHI, ASIAN_SLAW } from '../standard/toppings';
import { } from '../standard/tortilla';

const KOREAN = {
  toppings: [KIMCHI, KIMCHI, ASIAN_SLAW, ASIAN_SLAW],
  additionalToppings: [SOY_LIME_DRESSING, SOY_LIME_DRESSING, THAI_PEANUT_SAUCE, WHIPPED_COCONUT_CREAM],
  garnishes: [],
  mixins: [PICKLED_RED_ONION],
  queso: [],
  tortilla: [],
};

export default KOREAN;
