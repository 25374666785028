import React from 'react';
import { Container, Headline, Copy } from './styles';

const HeadlineAndCopy = ({
  copy,
  headline,
  headerWrap,
  injectStyles,
  isSubdued,
  marginTop,
  noWrap,
  smallCopy,
  ...props
}) => (
  <Container $injectStyles={injectStyles} $marginTop={marginTop} {...props}>
    <Headline $headerWrap={headerWrap} $isSubdued={isSubdued}>
      {headline}
    </Headline>
    <Copy $noWrap={noWrap} $smallCopy={smallCopy} $isSubdued={isSubdued} >
      {copy}
    </Copy>
  </Container>
);

HeadlineAndCopy.defaultProps = {
  marginTop: 0,
};

export default HeadlineAndCopy;
