import React, { useState, useEffect, Suspense } from 'react';
import { last, indexOf } from 'lodash';
import './App.css';
import { animateScroll as scroll, Events, scroller } from 'react-scroll';
import baseTypes from './constants/baseTypes';
import {
  getPreTitleHyperbole,
  recipeComponentGenerator,
  getIntroPhrase,
  getWelcomeMessage,
  baseTypeSelectionHandler,
} from './utilities';
import { toppingsToAvoidGarnish } from './recipes/standard/toppings';
import {
  MIXINS, ADDITIONAL_TOPPINGS, TOPPINGS, GARNISHES, TORTILLA, QUESO,
} from './recipes';
import { RecipeScene, HomeScene } from './components';

function App({ ...props }) {
  const searchParams = new URLSearchParams(window.location.search);
  const url = `${window.location.pathname}?${searchParams.toString()}`;
  const shouldIncludeUrl = url.length > 5;
  const [recipeStore, setRecipeStore] = useState(shouldIncludeUrl ? [url] : []);
  const [currentRecipeIndex, setCurrentRecipeIndex] = useState(recipeStore.length);
  const [categoryClickCount, setCategoryClickCount] = useState(0);
  const [displayRecipes, setDisplayRecipes] = useState(false);
  const [welcomeMessage] = useState(getWelcomeMessage);
  const [selectedDifficulty, setSelectedDifficulty] = useState('ANY');
  const [isProcessing, setIsProcessing] = useState(false);
  const [introPhrase, setIntroPhrase] = useState('');
  const [wantsCheese, setWantsCheese] = useState((window.location.search.length > 5) ? (searchParams.get('c') === 'true') : false);
  const [isVegan, setIsVegan] = useState(false); // Updated the variable name here
  const [GLUTEN, setGLUTEN] = useState(false);
  const [optionButtonsValue, setOptionButtonsValue] = useState(null);
  const [recipe, setRecipe] = useState({});
  const [recipesGeneratedCount, setRecipesGeneratedCount] = useState({});
  const [displayRecipeScene, setDisplayRecipeScene] = useState(false);

  // Emulate componentDidMount lifecycle
  useEffect(() => {
    Events.scrollEvent.register('begin', () => {});
    Events.scrollEvent.register('end', () => {});
    scroll.scrollToTop();
    getStateFromUrlQueryParams();
  }, []);

  const setCurrentRecipeIndexValue = (value) => {
    let currentRecipe;
    handleProcessing(400);
    if (value === 'LATEST') {
      setCurrentRecipeIndex(indexOf(recipeStore, last(recipeStore)));
      currentRecipe = last(recipeStore);
    }
    if (value === 'BACK') {
      setCurrentRecipeIndex((currentValue) => currentValue - 1);
      currentRecipe = recipeStore[currentRecipeIndex];
    }
    window.history.replaceState({}, '', currentRecipe);
    getStateFromUrlQueryParams();
  };

  const getStateFromUrlQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const hasFacebookParams = !!searchParams.get('fbclid');
    const hasRecipeParams = !!searchParams.get('b');
    if ((hasFacebookParams && !hasRecipeParams) || (!hasFacebookParams && !hasRecipeParams)) {
      return;
    }

    const baseType = searchParams.get('bT');
    const categoryBaseRecipes = (require(`./recipes/base/${baseType}.json`))[baseType];
    const getFromCodeInQueryParams = (recipeList, paramKey) => {
      if (recipeList) {
        return recipeList.filter((recipe) => recipe.code === searchParams.get(paramKey))[0] || '';
      }
      return '';
    };
    setIntroPhrase(getIntroPhrase());
    setOptionButtonsValue(baseType);
    if (!displayRecipes) {
      setDisplayRecipes(true);
    }
    setRecipe({
      hyperbole: searchParams.get('h') || '',
      base: getFromCodeInQueryParams(categoryBaseRecipes, 'b'),
      mixin: getFromCodeInQueryParams(MIXINS, 'm'),
      topping: getFromCodeInQueryParams(TOPPINGS, 't'),
      tortilla: getFromCodeInQueryParams(TORTILLA, 'tt'),
      queso: getFromCodeInQueryParams(QUESO, 'q'),
      additionalTopping: getFromCodeInQueryParams(ADDITIONAL_TOPPINGS, 'aT'),
      garnish: getFromCodeInQueryParams(GARNISHES, 'g'),
    });
  };

  function scrollTo(element) {
    if (window.innerWidth < 769 && ((categoryClickCount === 0) || (recipesGeneratedCount === 0))) {
      scroller.scrollTo(element, {
        duration: 500,
        offset: (element === 'third-scroll') ? 60 : -14,
        smooth: 'easeInOutQuint',
      });
    }
  }

  function handleClose() {
    setDisplayRecipeScene(false);
    scroll.scrollToTop();
  }

  function handleShow() {
    setDisplayRecipeScene(true);
    scroll.scrollToTop();
  }

  const setCheeseValue = (value) => {
    setWantsCheese(value);
    if (value === true) {
      setIsVegan(false);
    }
  };

  const setVeganValue = (value) => {
    setIsVegan(value);
    if (value === true) {
      setWantsCheese(false);
      setOptionButtonsValue(baseTypes.VEG);
    }
  };

  const setGlutenValue = (value) => {
    setGLUTEN(value);
  };

  const handleDifficulty = (selectedOption) => {
    setSelectedDifficulty(selectedOption);
  };

  const handleProcessing = (timeout) => {
    setIsProcessing(true);
    setTimeout(() => {
      setIsProcessing(false);
    }, timeout || 1125);
  };

  const handleOptionButtonValueChange = (value) => {
    setOptionButtonsValue(value);
    if (value !== baseTypes.VEG) {
      setIsVegan(false);
    }
    scrollTo('first-scroll');
    if (displayRecipes && isVegan === false) {
      getRecipes(value);
    }
    setCategoryClickCount((prevCount) => prevCount + 1);
  };

  const getRecipes = (value) => {
    const recipeBaseType = baseTypeSelectionHandler(value);
    const dietaryRestrictions = { GLUTEN, isVegan };
    // eslint-disable-next-line import/no-dynamic-require
    const categoryBaseRecipes = (require(`./recipes/base/${recipeBaseType}.json`))[recipeBaseType];
    // get and combine all recipes
    const base = recipeComponentGenerator(categoryBaseRecipes, {
      nullPercentage: 0,
      dietaryRestrictions,
      difficulty: selectedDifficulty.value,
      baseProfile: null,
      recipeType: 'base',
    });
    const baseProfile = base.profile;
    const mixin = recipeComponentGenerator(MIXINS, {
      nullPercentage: 50,
      dietaryRestrictions,
      optionButtonsValue,
      baseProfile,
      recipeType: 'mixins',
    });
    const additionalTopping = recipeComponentGenerator(ADDITIONAL_TOPPINGS, {
      nullPercentage: 32,
      dietaryRestrictions,
      optionButtonsValue,
      baseProfile,
      recipeType: 'additionalToppings',
    });

    const topping = recipeComponentGenerator(TOPPINGS, {
      nullPercentage: 0,
      dietaryRestrictions,
      difficulty: selectedDifficulty.value,
      optionButtonsValue,
      baseProfile,
      recipeType: 'toppings',
    });

    const shouldAvoidGarnish = toppingsToAvoidGarnish.includes(topping.name);

    const garnish = recipeComponentGenerator(GARNISHES, {
      nullPercentage: shouldAvoidGarnish ? 80 : 20,
      dietaryRestrictions,
      optionButtonsValue,
      baseProfile,
      recipeType: 'garnishes',
    });
    const tortilla = recipeComponentGenerator(TORTILLA, {
      nullPercentage: 0,
      dietaryRestrictions,
      baseProfile,
      recipeType: 'tortilla',
    });
    const queso = recipeComponentGenerator(QUESO, {
      nullPercentage: 0,
      dietaryRestrictions,
      optionButtonsValue,
      baseProfile,
      recipeType: 'queso',
    });
    const hyperbole = getPreTitleHyperbole(base);
    handleProcessing();

    setRecipesGeneratedCount((prevCount) => prevCount + 1);
    setIntroPhrase(getIntroPhrase());
    setRecipe({
      hyperbole,
      base,
      mixin,
      additionalTopping,
      tortilla,
      topping,
      garnish,
      queso,
    });
    setDisplayRecipes(true);
    searchParams.set('h', hyperbole);
    searchParams.set('bT', recipeBaseType);
    searchParams.set('b', base.code);
    searchParams.set('m', mixin ? mixin.code : '');
    searchParams.set('tt', tortilla.code);
    searchParams.set('t', topping ? topping.code : '');
    searchParams.set('g', garnish ? garnish.code : '');
    searchParams.set('aT', additionalTopping ? additionalTopping.code : '');
    searchParams.set('q', queso.code || '');
    searchParams.set('c', wantsCheese);
    searchParams.set('x', 'yz');
    const url = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', url);
    setRecipeStore((previousState) => [...previousState, url]);
    setCurrentRecipeIndex(indexOf(recipeStore, last(recipeStore)));
  };

  return (
    <div className="App">
      {displayRecipeScene ? (
        <Suspense fallback="">
          <RecipeScene
            welcomeMessage={welcomeMessage}
            recipe={displayRecipes ? recipe : null}
            onHide={handleClose}
            optionButtonsValue={optionButtonsValue}
            hyperbole={recipe.hyperbole}
            introPhrase={introPhrase}
            wantsCheese={wantsCheese}
            isProcessing={isProcessing}
            {...props}
          />
        </Suspense>
      ) : (
        <HomeScene
          currentRecipeIndex={currentRecipeIndex}
          recipeStore={recipeStore}
          welcomeMessage={welcomeMessage}
          categoryClickCount={categoryClickCount}
          recipesGeneratedCount={recipesGeneratedCount}
          recipe={recipe}
          isProcessing={isProcessing}
          hyperbole={recipe.hyperbole}
          wantsCheese={wantsCheese}
          isVegan={isVegan}
          GLUTEN={GLUTEN}
          displayRecipes={displayRecipes}
          optionButtonsValue={optionButtonsValue}
          selectedDifficulty={selectedDifficulty}
          onRecipeNavigate={setCurrentRecipeIndexValue}
          onRecipeGenerate={() => getRecipes(optionButtonsValue)}
          onDifficultySelect={handleDifficulty}
          onOptionValueChange={handleOptionButtonValueChange}
          onCheeseSelection={setCheeseValue}
          onVeganSelection={setVeganValue}
          onGlutenSelection={setGlutenValue}
          enableRecipeScene={handleShow}
          introPhrase={introPhrase}
        />
      )}
    </div>
  );
}

export default App;
