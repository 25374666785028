import { styled } from 'styletron-react';
import { media } from '../../constants/commonStyles';

const smallStyles = {
  maxWidth: media.gutterTrigger,
  height: '30px',
  marginLeft: '26%',
  [`@media (max-width: ${media.mobileMaxWidth})`]: {
    height: '18px',
    width: '80px',
    marginLeft: '15%',
  },
};

export const TitleContainer = styled('div', ({ $small }) => ({
  maxWidth: media.gutterTrigger,
  width: media.gutterTrigger,
  marginTop: '8px',
  marginBottom: '9px',
  ...$small ? smallStyles : {},
}));

export const TitleImage = styled('img', () => ({
  height: '60px',
  width: 'auto',
}));

export default {
  TitleContainer,
  TitleImage,
};
