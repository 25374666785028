import { isEmpty } from 'lodash';
import randomize from './randomizer';

const prePreviewHyperbole = [
  "Ballin'",
  "Bangin'",
  "Bussin'",
  'Bangkok Dangerous',
  'Bodacious',
  'Boss-mode',
  'Cataclysmic',
  'Chingón',
  'Dank',
  'Delicious',
  'Devastating',
  'Dope',
  'Double-cheeked',
  'Earth-shattering',
  'Fabulicious',
  'Filthy',
  'Full-Send',
  'Fantastic',
  'Gas station gourmet',
  'Heady',
  'Hypebeast hot',
  'Insane',
  'Indecent',
  'Inappropriate',
  'Loco-delicioso',
  'Lusty',
  'Luscious',
  'Legendary',
  'Low-Key Naughty',
  'Mad-yummy',
  'Mouth-gasmic',
  'Nummy',
  'Nibbly bits of',
  'Paradigm-shifting',
  'Ratchet',
  'Risky-bizniz',
  'Scandalous',
  'Sacrilicious',
  'Sensual',
  'Straight-up lit',
  'Sweet, luscious',
  'Sultry',
  'Sleazy',
  'Spicy Boi',
  'Thicc & saucy',
  'Trashy',
  'Tantalizing',
  'Unholy',
  'Zaza-level Yummy',
  'Zero chill',
];

export function getPreTitleHyperbole(base) {
  const firstLetter = base.name.charAt(0).toUpperCase();
  const filtered = prePreviewHyperbole.filter((word) => word.charAt(0).toUpperCase() === firstLetter);
  const getStringArray = isEmpty(filtered) ? prePreviewHyperbole : filtered;
  return randomize(getStringArray);
}

export default getPreTitleHyperbole;
