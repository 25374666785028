import { styled } from 'styletron-react';
import { adTypes } from '../../constants/adTypes';

export const Container = styled('div', ({ $adType }) => ({
  border: '2px solid pink',
  width: `${adTypes[$adType].width}px`,
  height: `${adTypes[$adType].height}px`,
}));

export default { Container };
