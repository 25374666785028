import { dietaryCodes, baseTypes } from '../../constants';

export const CHIMICHURRI = {
  name: 'chimichurri',
  code: 'chm',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    'VEGAN',
    'VEGETARIAN',
  ],
  ingredients: [
    {
      name: 'parsley',
      measurement: '1 bunch',
      imageCode: 'cilantro',
    },
    {
      name: 'cilantro',
      measurement: '1 bunch',
      imageCode: 'cilantro',
    },
    {
      name: 'red onion',
      measurement: '1/2 cup diced',
      imageCode: 'red-onion',
    },
    {
      name: 'oregano',
      measurement: '1 tsp dried',
      imageCode: 'oregano',
    },
    {
      name: 'garlic',
      measurement: '3 cloves peeled',
      imageCode: 'garlic',
    },
    {
      name: 'lemon',
      measurement: '1 juiced',
      imageCode: 'lemon',
    },
    {
      name: 'red wine vinegar',
      measurement: '1 tbsp',
      imageCode: 'vinegar',
    },
    {
      name: 'salt',
      measurement: '1/4 tsp',
      imageCode: 'salt',
    },
    {
      name: 'black pepper',
      measurement: '1/4 tsp',
      imageCode: 'black-pepper',
    },
    {
      name: 'red pepper flakes',
      measurement: '1/4 tsp',
      imageCode: 'aleppo',
    },
    {
      name: 'extra-virgin olive oil',
      measurement: '1/2 cup',
      imageCode: 'olive-oil',
    },
  ],
  difficulty: 4,
  description: "Chimichurri is more than just fun to say. This Argentinian steak-enhancer is undoubtedly bomb over just about anything. You won't need this much for your taco night. Store the rest in your freezer and get it back out next time you make ribeyes.",
  preparation: [
    'Add all ingredients except the olive oil to a food processor, and pulse a few times until chopped.',
    'Slowly stream in the olive oil, while pulsing the mixture a few more times until the olive oil is combined, and stopping to scrape down the sides of the food processor if needed.',
  ],
};

export const WHIPPED_COCONUT_CREAM = {
  name: 'whipped coconut cream',
  code: 'wccat',
  dietaryCodes: [dietaryCodes.VEGAN, dietaryCodes.VEGETARIAN],
  basePairings: [
    baseTypes.VEG,
    baseTypes.VEG,
    baseTypes.CHICKEN,
    baseTypes.BEEF,
    baseTypes.SEA,
    baseTypes.SEA,
    baseTypes.INFINITE,
  ],
  ingredients: [
    {
      name: 'coconut cream',
      measurement: '1 can',
      imageCode: 'coconut cream',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'Refrigerate can of coconut cream at least 4 hours.',
    'Place glass or metal mixing bowl in the freezer for 30 minutes prior to preparation.',
    'Open can gently, being careful to avoid disturbing contents.',
    'Using a spoon, scoop the solids into the mixing bowl, reserving remaining liquid for some other unrelated use.',
    "Using an electric mixer or your favorite whisking workout video, mix or whisk the cream until it thickens enough to be 'dolloped'.",
    'Optional: add a teaspoon of confectioners sugar and/or a squeeze of lime while mixing.',
  ],
};

export const SRIRACHA_MAYO = {
  name: 'sriracha mayo',
  code: 'srmo',
  dietaryCodes: [dietaryCodes.VEGETARIAN],
  basePairings: [
    baseTypes.SEA,
    baseTypes.SEA,
    baseTypes.SEA,
    baseTypes.BEEF,
    baseTypes.INFINITE,
    baseTypes.CHICKEN,
  ],
  ingredients: [
    {
      name: 'mayonnaise',
      measurement: '1/4 cup',
      imageCode: 'mayonnaise',
    },
    {
      name: 'sriracha',
      measurement: '1 tbsp',
      imageCode: 'sriracha',
    },
    {
      name: 'lime juice',
      measurement: '2 wedges',
      imageCode: 'limes',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'Whisk lime juice, mayo, and sriracha together in a small bowl.',
  ],
};

export const YUMYUM_SAUCE = {
  name: 'yum-yum sauce',
  code: 'yys',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'mayonnaise',
      measurement: '1 cup',
      imageCode: 'mayonnaise',
    },
    {
      name: 'butter',
      measurement: '1 tbsp melted',
      imageCode: 'butter',
    },
    {
      name: 'tomato paste',
      measurement: '1 tbsp',
      imageCode: 'olive-oil',
    },
    {
      name: 'maple syrup',
      measurement: '1 tbsp',
      imageCode: 'maple-syrup',
    },
    {
      name: 'garlic powder',
      measurement: '1/2 tsp',
      imageCode: 'garlic',
    },
    {
      name: 'paprika',
      measurement: '1/2 tsp',
      imageCode: 'paprika',
    },
    {
      name: 'water',
      measurement: '2 tbsp',
      imageCode: 'water',
    },
  ],
  difficulty: 3,
  description: "Interestingly, the english translation of this Japanese classic is 'dope on everything sauce'.",
  preparation: [
    'Whisk all ingredients together in a bowl and serve, adding water slowly to desired thickness.',
  ],
};

export const SOY_LIME_DRESSING = {
  name: 'spicy soy-lime dressing',
  code: 'ssld',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
    dietaryCodes.VEGAN,
  ],
  ingredients: [
    {
      name: 'lime',
      measurement: '1 juiced',
    },
    {
      name: 'mirin',
      measurement: '1 tbsp',
    },
    {
      name: 'soy sauce',
      measurement: '1 tbsp',
    },
    {
      name: 'light olive oil',
      measurement: '1 tbsp',
    },
    {
      name: 'chili garlic sauce',
      measurement: '1 big tsp',
    },

  ],
  difficulty: 2,
  description: 'An easy, delicious Asian zing.',
  preparation: ['Whisk all ingredients together in a small bowl, olive-oil last.'],
};

export const MINT_CHUTNEY = {
  name: 'mint chutney',
  code: 'mc',
  basePairings: [
    baseTypes.LAMB,
    baseTypes.LAMB,
    baseTypes.CHICKEN,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
    dietaryCodes.VEGAN,
  ],
  ingredients: [
    {
      name: 'fresh mint',
      measurement: '1 cup packed',
    },
    {
      name: 'fresh cilantro',
      measurement: '1 sprig',
    },
    {
      name: 'garlic',
      measurement: '2 cloves',
    },
    {
      name: 'sea salt',
      measurement: '1/2 tsp',
    },
    {
      name: 'lemon',
      measurement: '1/2 juiced & zested',
    },
    {
      name: 'fresh green serrano chile',
      measurement: '1',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'Add all ingredients to a food processor (or blender, if you just cannot be bothered). If you prefer a sweeter chutney, add a teaspoon of honey (or sugar...again, if you cannot be bothered).',
    'Pulse, and add water by the teaspoon until you have reached your desired consistency. Serve on the side to lightly spoon-drizzle onto your tacos.',
  ],
};

export const THAI_PEANUT_SAUCE = {
  name: 'thai peanut sauce',
  code: 'tpns',
  basePairings: [
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
    dietaryCodes.VEGAN,
  ],
  ingredients: [
    {
      name: 'peanut butter',
      measurement: '1/2 cup',
    },
    {
      name: 'soy sauce (or tamari for gluten-free)',
      measurement: '2 tbsp',
    },
    {
      name: 'rice vinegar',
      measurement: '1 tbsp',
    },
    {
      name: 'chili garlic sauce',
      measurement: '1 tbsp',
    },
    {
      name: 'lime',
      measurement: '1 juiced',
    },
    {
      name: '(Optional) Mirin',
      measurement: '1 tsp',
    },
    {
      name: 'garlic',
      measurement: '2 cloves minced',
    },

  ],
  difficulty: 2,
  description: "A easy Thai mainstay you'll want in your arsenal for many occasions.",
  preparation: ['Whisk all ingredients together in a small bowl. Add water, 1 teaspoon at a time, to desired thickness.'],
};

export const SPICED_YOGURT_SAUCE = {
  name: 'spiced yogurt sauce',
  code: 'sys',
  basePairings: [
    baseTypes.BEEF,
    baseTypes.CHICKEN,
    baseTypes.VEG,
    baseTypes.SEA,
    baseTypes.PORK,
    baseTypes.LAMB,
    baseTypes.BRUNCH,
    baseTypes.INFINITE,
  ],
  dietaryCodes: [
    dietaryCodes.VEGETARIAN,
  ],
  ingredients: [
    {
      name: 'Greek yogurt',
      measurement: '1 cup',
    },
    {
      name: 'cinnamon',
      measurement: '1/2 tsp',
    },
    {
      name: 'cumin',
      measurement: '1/2 tsp ground',
    },
    {
      name: 'coriander',
      measurement: '1/2 tsp ground',
    },
    {
      name: 'smoked paprika',
      measurement: '1/4 tsp',
    },
    {
      name: 'aleppo pepper',
      measurement: '1/2 tsp',
    },
    {
      name: 'mint',
      measurement: '1/4 cup fresh chopped',
    },
    {
      name: 'lemon',
      measurement: '1 small juiced and zested',
    },
    {
      name: 'sea salt',
      measurement: '1/2 tsp',
    },
  ],
  difficulty: 2,
  description: '',
  preparation: [
    'Combine and whisk all ingredients in a small mixing bowl.',
    'Add water, 1 tsp at a time if mixture is too thick. Serve.',
  ],
};

export const ADDITIONAL_TOPPINGS = [
  CHIMICHURRI,
  WHIPPED_COCONUT_CREAM,
  SOY_LIME_DRESSING,
  SRIRACHA_MAYO,
  YUMYUM_SAUCE,
  THAI_PEANUT_SAUCE,
  SPICED_YOGURT_SAUCE,
  MINT_CHUTNEY,
];

export default ADDITIONAL_TOPPINGS;
