import { randomize } from '.';
import { baseTypes } from '../constants';

export function baseTypeSelectionHandler(baseType) {
  const standaloneBaseTypes = Object.keys(baseTypes).filter((type) => type !== baseTypes.INFINITE);
  if (baseType === baseTypes.INFINITE) {
    return randomize(standaloneBaseTypes);
  }
  return baseType;
}
