import { styled } from 'styletron-react';
import { colors, unit } from '../../constants/commonStyles.js';

export const Container = styled('div', ({ $isProcessing }) => {
  const processingStyles = {
    overflow: 'auto',
    paddingTop: '24px',
    height: '100%',
  };
  return {
    minWidth: '100%',
    width: '100%',
    backgroundImage: colors().gradientBackgroundA,
    boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)',
    ...($isProcessing ? processingStyles : {}),
  };
});

export const HelperContainer = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: unit[1],
}));

export default {
  Container,
  HelperContainer,
};
