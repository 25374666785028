import { styled } from 'styletron-react';
import { unit } from '../../constants/commonStyles';

const headerWrap = {
  width: '60%',
};

const subdued = {
  color: 'silver',
};

const smallCopyStyles = {
  fontSize: '13px',
  marginTop: unit[0],
};

const noWrapStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
};

export const Container = styled('div', ({ $injectStyles }) => ({
  ...($injectStyles || {}),
}));

export const Headline = styled('h3', ({ $isSubdued, $headerWrap }) => ({
  ...($headerWrap ? headerWrap : {}),
  ...($isSubdued ? subdued : {}),
}));

export const Copy = styled('p', ({ $smallCopy, $isSubdued, $noWrap }) => ({
  ...($smallCopy ? smallCopyStyles : {}),
  ...($noWrap ? noWrapStyles : {}),
  ...($isSubdued ? subdued : {}),
}));

export default {
  Container,
  Copy,
  Headline,
};
