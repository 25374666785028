import { styled } from 'styletron-react';

export const Container = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  zIndex: '0',
  flexWrap: 'wrap',
});

export default {
  Container,
};
