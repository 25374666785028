import randomize from './randomizer';

const authors = [
  'Lao Tzu',
  'Tomoe Gozen (巴 御前)',
  'Sun Tzu',
  'Ancient Chinese Proverb',
  'Confucius',
  'Toyotomi Hideyoshi (豊臣 秀吉)',
  'Kusunoki Masashige (楠木 正成)',
  'Yumiko of the Infinite Tacos',
];

const quotes = [
  "I wonder if there's a taco out there thinking of me too.",
  'A balanced diet is a taco in each hand.',
  'I wish I was full of tacos instead of emotions.',
  'Ways to My Heart: Buy Me Tacos, Make Me Tacos, Be Tacos.',
  "Tacos don't ask questions. Tacos understand.",
  'You cannot make everybody happy, you are not a taco.',
  'I like my tacos like I like my relationships—spicy and a little messy.',
  'Broke? Stressed? Just add tacos.',
  'If love had a flavor, it would taste like tacos.',
  'Yesterday I really wanted tacos. Today, I’m eating tacos. Follow your dreams.',
  "I'm into fitness. Fit'ness taco in my mouth.",
  "All I'm saying is- you've never seen me crying and eating tacos at the same time.",
  'Tacocat spelled backwards is ... tacocat.',
  'One does not simply eat just one taco.',
  'I like my tacos spicy and my friends slightly questionable.',
  'If you can’t find me, I’m probably lost in the sauce.',
  'Some search for the meaning of life; I search for the perfect taco.',
  'May your tacos be crunchy and your guac be extra.',

];

export function getWelcomeMessage() {
  return {
    author: randomize(authors),
    quote: randomize(quotes),
  };
}
