export const adTypes = {
  LEADERBOARD: {
    name: 'LEADERBOARD',
    width: 728,
    height: 90,
  },
  LARGE_RECTANGLE: {
    name: 'LARGE_RECTANGLE',
    width: 336,
    height: 280,
  },
  MEDIUM_RECTANGLE: {
    name: 'MEDIUM_RECTANGLE',
    width: 300,
    height: 250,
  },
  WIDE_SKYSCRAPER: {
    name: 'WIDE_SKYSCRAPER',
    width: 160,
    height: 600,
  },
  SKYSCRAPER: {
    name: 'SKYSCRAPER',
    width: 120,
    height: 600,
  },
  BUTTON1: {
    name: 'BUTTON1',
    width: 120,
    height: 90,
  },
  BUTTON2: {
    name: 'BUTTON2',
    width: 120,
    height: 60,
  },
  MICROBAR: {
    name: 'MICROBAR',
    width: 88,
    height: 31,
  },
};

export default adTypes;
